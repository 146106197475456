import React from "react";
import { useCookies } from "react-cookie";
import { PricingCard } from "zera";

import {
  getCatalogoPlanId,
  getCredentials,
  getRedirectUrl,
} from "../../helpers";
import showTexts from "../../helpers/functions/showTexts";
import {
  ICatalog,
  IPricingCard,
  IPricingDetails,
  IUser,
} from "../../interfaces";
import { apiCatalog, apiPatient } from "../../services";
import {
  PriceCardContainer,
  PriceHeaderTextContainer,
  PriceSectionContainer,
  PriceSectionContent,
  PriceSectionFooterText,
  PriceTextFooter,
  PriceTextTitle,
  SeparatorPriceSection,
} from "./style";

import TagManager from "react-gtm-module";

export interface IPriceSectionProps {
  internationalization: "enUS" | "es" | "ptBR";
  userLogged: IUser | null;
}

export const PriceSection: React.FunctionComponent<IPriceSectionProps> = (
  props
) => {
  const showTextsi18n = showTexts(props.internationalization);

  const { b2cToken, refreshToken, patientToken, token } = getCredentials();
  const [cookies, setCookie] = useCookies(["name" as string]);
  const catalogsStorage = JSON.parse(
    localStorage.getItem("@price-section:catalogs")
  );
  const { innerWidth: width } = window;
  const [widthDimensions, setWidthDimensions] = React.useState(width);
  const [catalogsApi, setCatalogsApi] = React.useState<ICatalog[]>(null);
  const [catalogsCard, setCatalogsCard] = React.useState<IPricingCard[]>(
    catalogsStorage as IPricingCard[]
  );

  const isMobile: boolean = widthDimensions <= 1088;

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWidthDimensions(width);
  };

  const changeRedirect = React.useCallback(async (selectedCatalog) => {

    let productPrice = selectedCatalog.name.toLocaleLowerCase() !== "consulta avulsa"
    ? (parseFloat(selectedCatalog.price) * 12).toFixed(2)
    : parseFloat(selectedCatalog.price).toFixed(2)
    

    const response = await apiPatient
      .get("/users")
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
    if (props.userLogged !== null && response) {
      if (
        patientToken !== null &&
        b2cToken !== null &&
        refreshToken !== null &&
        token !== null
      ) {
        const mapTokens = {
          patientToken: patientToken,
          b2cToken: b2cToken,
          refreshToken: refreshToken,
          token: token,
        };
        const { mapQuery, url } = getRedirectUrl(
          mapTokens as Record<string, string>,
          "",
          "L2FwcC1wYXltZW50"
        );
        for (const [key, value] of mapQuery) {
          localStorage.setItem(key, value);
        }
        window.location.replace(url);
      } else {

        
      
        window.location.assign(
          "/login?redirect=L2FwcC1wYXltZW50&clientId=conecta-web&plano=" +
            selectedCatalog.name +
            "&valorPlano=" +
            productPrice +
            ""
        );
      }
    } else {
      console.log('selectedCatalog', selectedCatalog)
      window.location.assign(
        "/login?redirect=L2FwcC1wYXltZW50&clientId=conecta-web&plano=" +
          selectedCatalog.name +
          "&valorPlano=" +
          productPrice +
          ""
      );
    }
  }, []);

  const handleSelectCatalog = React.useCallback(
    async (benefit: IPricingCard) => {
      if (catalogsApi.length > 0) {
        const filterCatalog = catalogsApi.filter(
          (catalog) =>
            catalog.name.split(" ")[0].toLocaleLowerCase() ===
            benefit.title.split(" ")[0].toLocaleLowerCase()
        );
        const catalog = getCatalogoPlanId(filterCatalog[0].id);
        setCookie("@product_id", catalog, {
          path: "/",
        });
        setCookie("@service_id", "d4600e82-dbba-4bb4-8936-8cc6ba59df51", {
          path: "/",
        });
        setCookie("@source_platform", "CONECTA_B2C_ed25519", { path: "/" });
        localStorage.setItem("buy-flag", "true");
        localStorage.setItem("@current_plan", JSON.stringify(filterCatalog[0]));
        localStorage.setItem("buy-flag", "true");

        // GTM EVENT
        const tagManagerArgs = {
          gtmId: "GTM-THGH7HH",
          dataLayer: {
            event: "einstein_telemedicina",
            event_name: "selecionar_plano",
            step: "step1",
            nome_plano: filterCatalog[0].name,
            valor_plano:
              filterCatalog[0].name.toLowerCase() !== "consulta avulsa"
                ? (parseFloat(filterCatalog[0].price) * 12).toFixed(2)
                : parseFloat(filterCatalog[0].price).toFixed(2),
          },
        };

        console.log(filterCatalog[0].name.toLowerCase() !== "consulta avulsa"
        ? (parseFloat(filterCatalog[0].price) * 12).toFixed(2)
        : parseFloat(filterCatalog[0].price).toFixed(2))

        TagManager.initialize(tagManagerArgs);
        changeRedirect(filterCatalog[0]);
      } else {
        console.log("Erro ao buscar os produtos no catalogo.");
      }
    },
    [catalogsApi, changeRedirect]
  );

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [width]);

  React.useEffect(() => {
    apiCatalog
      .get(`/services/${process.env.REACT_APP_CATALOG_SERVICE_ID}`)
      .then(({ data: { plans } }) => {
        setCatalogsApi(plans);
        let cardsPricing = [] as IPricingCard[];
        (plans as ICatalog[]).map((catalog: ICatalog) => {
          let benefitsCatalog = [] as IPricingDetails[];
          catalog.differentials.map(({ id, description, order }) =>
            benefitsCatalog.push({
              id,
              label: description,
              order: order,
            })
          );
          const benefitsOrder = benefitsCatalog.sort(function (a, b) {
            if (a.order < b.order) return -1;
          });
          const subTitle =
            (catalog.plan_contract_type.description.toLowerCase() as string) ===
            "avulso"
              ? "Pagamento Único"
              : "Pagamento Anual";

          const warningButtom =
            (catalog.plan_contract_type.description.toLowerCase() as string) ===
            "avulso"
              ? true
              : false;
          const descriptionPrice =
            catalog.name.toLowerCase() === "familiar"
              ? "Valor mensal por membro"
              : catalog.name.toLowerCase() === "individual"
              ? "Valor mensal"
              : "Valor por consulta";
          const catalogPrice = catalog.price.replace(".", ",");
          const recommended =
            catalog.name.toLowerCase() === "familiar" ? true : false;
          cardsPricing.push({
            badgeTitle: "Recomendado",
            benefits: benefitsOrder,
            descriptionPrice: descriptionPrice,
            id: catalog.id,
            labelButton: "Quero contratar",
            price: `R$ ${catalogPrice}`,
            recommended: recommended,
            subTitle: subTitle,
            title: catalog.name,
            warningBottom: warningButtom,
            warningDescription: `Após a contratação terá 24 horas \npara realizar a consulta`,
          });
        });
        cardsPricing.sort((a) => {
          if (a.title.toLowerCase() === "familiar") {
            return 1;
          } else if (a.title.toLowerCase() === "individual") {
            return -1;
          } else {
            return 0;
          }
        });
        localStorage.setItem(
          "@price-section:catalogs",
          JSON.stringify(cardsPricing)
        );
        setCatalogsCard(cardsPricing);
      })
      .catch((err: string) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  return (
    <PriceSectionContainer id="plans">
      <PriceSectionContent
        width={isMobile ? "100%" : "1040px"}
        paddingLeft={isMobile ? "" : ""}
        paddingRight={isMobile ? "" : ""}
      >
        <PriceHeaderTextContainer padding={isMobile ? "24px" : ""}>
          <PriceTextTitle
            fontSize={isMobile ? "7" : "8"}
            text={
              isMobile
                ? showTextsi18n.titlePriceSectionMobile
                : showTextsi18n.titlePriceSection
            }
          />
        </PriceHeaderTextContainer>
        <PriceCardContainer
          className="container"
          padding={isMobile ? "24px" : ""}
        >
          <SeparatorPriceSection margin={isMobile ? 2.5 : 4} />
          {catalogsCard !== null && (
            <PricingCard
              cardsBenefits={showTextsi18n.cardsCatalogs}
              onPress={(benefit: IPricingCard) => handleSelectCatalog(benefit)}
            />
          )}
        </PriceCardContainer>
        <SeparatorPriceSection margin={isMobile ? 0.75 : 0.875} />
      </PriceSectionContent>
    </PriceSectionContainer>
  );
};
