import styled from "styled-components";
import { Typography } from "zera";

interface IPriceSectionContentProps {
  paddingRight: string;
  paddingLeft: string;
  width: string;
}

interface IPriceSectionSeparatorProps {
  margin: number;
}

interface IPriceSectionFlagProps {
  self: string;
  align: string;
}

interface IPriceSectionTextProps {
  align: string;
}

interface IPriceSectionCardProps {
  padding: string;
}
interface IPriceContainerCardProps {
  padding: string;
}

export const PriceSectionContainer = styled.section`
  flex: 1;
  width: 100vw;
  height: 100%;
  display: flex;
  margin-top: 96px;
  padding-bottom: 96px;
  justify-content: center;
`;

export const PriceSectionContent = styled.div<IPriceSectionContentProps>`
  width: ${({ width }) => width};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
`;

export const PriceSectionFooter = styled.div<IPriceSectionContentProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PriceTextTitle = styled(Typography).attrs(() => ({
  fontFamily: "Inter",
  fontWeight: 600,
  lineHeight: "1.5",
}))``;

export const PriceTextFooter = styled(Typography).attrs(() => ({
  fontFamily: "Inter",
  fontWeight: 500,
  lineHeight: "1",
  alignment: "center",
}))``;

export const SeparatorPriceSection = styled.div<IPriceSectionSeparatorProps>`
  margin-top: ${({ margin }) => margin / 2}rem;
  margin-bottom: ${({ margin }) => margin / 2}rem;
`;
export const SeparatorPriceSectionFlag = styled.div<IPriceSectionSeparatorProps>`
  margin-right: ${({ margin }) => margin}rem;
`;

export const CreditCardContainer = styled.div<IPriceSectionFlagProps>`
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  padding-left: ${({ self }) => self};
  align-self: ${({ align }) => align};
`;

export const PriceCardContainer = styled.div<IPriceContainerCardProps>`
  width: 100%;
  margin-bottom: 32px;
  padding: 0 ${({ padding }) => padding};

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; /* mozilla */

  @media (max-width: 1088px) {
    overflow-x: scroll;
    overflow-scrolling: touch; /* iOS */
    -webkit-overflow-scrolling: touch; /* iOS */
    width: 100% !important;
    & > div {
      min-width: 1044px;
    }
  }
`;

export const PriceSectionFooterText = styled.div<IPriceSectionTextProps>`
  align-self: ${({ align }) => align};
`;

export const PriceHeaderTextContainer = styled.div<IPriceSectionCardProps>`
  padding-left: ${({ padding }) => padding};
`;
