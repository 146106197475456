export const getCredentials = () => {
  return {
    b2cToken: localStorage.getItem("b2cToken"),
    patientToken: localStorage.getItem("patientToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    token: localStorage.getItem("token"),
    tokenBuy: localStorage.getItem("tokenBuy"),
  };
};

export const clearCredentials = () => {
  localStorage.removeItem("b2cToken");
  localStorage.removeItem("patientToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("tokenBuy");
  localStorage.removeItem("@conecta:user");
};

export const hasToken = () => {
  const { b2cToken, patientToken, token } = getCredentials();
  return !!(b2cToken && patientToken && token);
};
