import axios from "axios";

import { clearCredentials, getCredentials, hasToken } from "../helpers";

const urlPatient = `${process.env.REACT_APP_API_GATEWAY}/patient`;

const apiPatient = axios.create({
  baseURL: urlPatient,
});

apiPatient.interceptors.request.use((config) => {
  try {
    const { token, b2cToken, patientToken, tokenBuy } = getCredentials();
    const hasCredentials = hasToken();
    if (hasCredentials) {
      config.headers["x-access-token"] = `Bearer ${token}`;
      config.headers["x-access-patient-token"] = `Bearer ${patientToken}`;
      config.headers["x-access-b2c-token"] = `Bearer ${b2cToken}`;
    }
    if (tokenBuy) {
      config.headers["x-access-buy-token"] = `Bearer ${tokenBuy}`;
    }
  } catch (err) {
    clearCredentials();
    console.error(err);
  } finally {
    return config;
  }
});

export default apiPatient;
