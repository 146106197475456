export function getRedirectParams(
  tokens: Record<string, string>,
  serverUrl: string,
  windowUrl: string
) {
  const url = !!windowUrl ? window.atob(windowUrl) : serverUrl;
  const [urlWithouQuery, paramsFromWindow = ""] = url.split("?");
  const baseParams = paramsFromWindow.split("&").filter(String);
  const tokenParam = Object.entries(tokens).reduce(
    (acc, [key, value]) => acc.concat(`${key}=${value}`),
    []
  );
  const finalParams = baseParams.concat(tokenParam).join("&");
  return {
    mapQuery: finalParams.split("&").map((value) => value.split("=")),
    query: finalParams,
    urlWithouQuery,
  };
}

export function getRedirectUrl(
  tokens: Record<string, string>,
  serverUrl: string,
  windowUrl: string
) {
  const { query, urlWithouQuery, mapQuery } = getRedirectParams(
    tokens,
    serverUrl,
    windowUrl
  );
  const url = `${urlWithouQuery}?${query}`;
  return { url, mapQuery };
}
