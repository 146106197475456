import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };
  return {
    footerPriceSection: i18n[internationalization].footerPriceSection,
    footerPriceSectionMobile:
      i18n[internationalization].footerPriceSectionMobile,
    titlePriceSection: i18n[internationalization].titlePriceSection,
    titlePriceSectionMobile: i18n[internationalization].titlePriceSectionMobile,
    cardsCatalogs: i18n[internationalization].cardsCatalogs,
  };
};

export default showTexts;
