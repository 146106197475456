import { IUser } from "./interfaces";
import { PriceSection } from "./modules";

type TypeInternationalization = "enUS" | "es" | "ptBR";

export default function Root() {
  const internationalization = localStorage.getItem(
    "@conecta:internationalization"
  ) as TypeInternationalization;

  const userLogged = JSON.parse(
    localStorage.getItem("@conecta:user")
  ) as IUser | null;

  return (
    <PriceSection
      internationalization={
        internationalization?.length > 0 ? internationalization : "ptBR"
      }
      userLogged={userLogged}
    />
  );
}
